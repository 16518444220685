import React, { useEffect } from 'react';
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FormProvider, useForm } from 'react-hook-form';

import { Section } from '@/components/Layout';
import Page from '@/components/Pages/Page';
import { useAuth } from '@/context/AuthContext';
import { Button, H1 } from '@/elements';
import { PATHS } from '@/lib/constants/paths';
import { ImpactLoopLogo } from '@/components/Icons/ImpactLoopLogo';
import PasswordInput from '@/components/Form/PasswordInput';

export default function SignIn() {
  const methods = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const router = useRouter();
  const { isAuthenticated, signIn, signInError, isSigningIn } = useAuth();

  const onSubmit = async (values) => {
    if (values.email && values.password) {
      signIn(values.email, values.password);
    }
  };

  // redirect if signed in
  useEffect(() => {
    if (isAuthenticated) {
      router.push(PATHS.homepage);
    }
  }, [isAuthenticated, router]);

  return (
    <Page withHeader={false}>
      <Section
        columnProps={{
          height: '100%',
        }}
        contentProps={{
          pt: { base: 'xl', md: 0 },
          maxWidth: '400px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        height='100%'
      >
        <Box>
          <Center flexDir='column' mb='xl'>
            <Box w='10rem' h='10rem' mb='lg'>
              <ImpactLoopLogo variant='symbolGreen' />
            </Box>
            <H1>Sign in</H1>
          </Center>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={!!errors.email} mb='md'>
                <Input
                  {...register('email', {
                    required: 'This field is required',
                  })}
                  id='email'
                  placeholder='Email'
                  type='email'
                />
                {errors.email && (
                  <FormErrorMessage>{!!errors.email.message}</FormErrorMessage>
                )}
              </FormControl>

              <PasswordInput />

              {signInError && (
                <Text color='red' mt='2'>
                  {signInError}
                </Text>
              )}

              <Center mb='lg' mt='sm'>
                <Button isLoading={isSigningIn} minWidth={200} type='submit'>
                  Sign In
                </Button>
              </Center>
            </form>
          </FormProvider>
          {/* <Box>
            <Center mb='lg' mt='sm'>
              <Button
                onClick={() => signInNext('google')}
                minWidth={200}
                type='submit'
              >
                Sign In with Google
              </Button>
            </Center>
          </Box> */}
        </Box>
      </Section>
    </Page>
  );
}
